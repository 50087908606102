import React, { useState, useEffect } from "react";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import { Link } from "react-router-dom";
import ResponsivePagination from "react-responsive-pagination";
import Oval from "../Loader/CircleLoade";
import * as Constants from "../../../Constants/index";
import axios from "axios";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { saveAs } from "file-saver";
import longarrow from "../../../assets/img/longarrow.svg";

export default function RedemptionReport() {
  const navigate = useNavigate();
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [results, setResults] = useState([]);
  const [totalPages, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchRecord, setSearchRecord] = useState(null);
  const [limit] = useState("10");
  const [loading, setLoading] = useState(false);
  const [selectStatus, setSelectStatus] = useState("");

  const dateString = fromDate;
  const formattedDate = moment(dateString).format("YYYY-MM-DD HH:mm:ss");
  const dateString2 = toDate;
  const formattedDate2 = moment(dateString2).format("YYYY-MM-DD 23:59:59");
  const start = formattedDate.replace(/:/g, "%3A");
  const end = formattedDate2.replace(/:/g, "%3A");
  useEffect(() => {
    if (currentPage !== 1) {
      redemptionReport(selectStatus);
    } else {
      redemptionReport("");
    }
  }, [currentPage]);

  const redemptionReport = (userName) => {
    let token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    } else {
      const searchFromDate = fromDate === "" ? "" : `start=${start}&`;
      const searchToDate = toDate === "" ? "" : `end=${end}&`;
      const searchStatus = userName === "" ? "" : `status=${userName}&`;
      if (fromDate !== "" && toDate === "") {
        swal({
          text: "Please select to date",
          icon: "error",
          timer: 2000,
          buttons: false,
        });
        return; // Return early if reason is not selected when status is 'reject'
      }
      if (fromDate === "" && toDate !== "") {
        swal({
          text: "Please select from date",
          icon: "error",
          timer: 2000,
          buttons: false,
        });
        return; // Return early if reason is not selected when status is 'reject'
      }
      if (
        fromDate !== "" &&
        toDate !== "" &&
        new Date(fromDate) > new Date(toDate)
      ) {
        swal({
          text: "From date should be less than to date",
          icon: "error",
          timer: 2000,
          buttons: false,
        });
        return; // Return early if "from" date is greater than or equal to "to" date
      }
      try {
        setLoading(true);

        axios
          .get(
            Constants.baseUrl +
              `reports/program1/redemptionList/list?${searchStatus}${searchFromDate}${searchToDate}sortBy=createDateTime&sortAsc=true&limit=${limit}&page=${currentPage}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Accept-Language": localStorage.getItem("langauge"),
                appVersion: Constants.av,
                platform: Constants.pt,
                company: Constants.company,
                program: Constants.program,
              },
            }
          )
          .then((res) => {
            if (res.status === 201) {
              if (res.data.code === 10001) {
                setResults(res.data.output.results);
                setTotalPages(res.data.output.totalPages);
                setSearchRecord();
                setLoading(false);
              } else if (res.data.code === 12002) {
                setLoading(false);
                setSearchRecord(res.data.output.results);
                setTotalPages(res.data.output.totalPages);
              } else {
                // swal({
                //     text: res.data.msg,
                //     icon: 'error',
                //     timer: 2000,
                //     buttons: false,
                // })
              }
            } else {
              swal({
                text: res.data.msg,
                icon: "error",
                timer: 2000,
                buttons: false,
              });
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };
  const download = () => {
    let token = localStorage.getItem("token");
    try {
      const searchFromDate = fromDate === "" ? "" : `start=${start}&`;
      const searchToDate = toDate === "" ? "" : `end=${end}`;
      const searchStatus = selectStatus === "" ? "" : `status=${selectStatus}&`;
      setLoading(true);
      axios
        .get(
          Constants.baseUrl +
            `reports/program1/download/redemptionListReport?${searchStatus}${searchFromDate}${searchToDate}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Accept-Language": localStorage.getItem("langauge"),
              appVersion: Constants.av,
              platform: Constants.pt,
              company: Constants.company,
              program: Constants.program,
            },
            responseType: "blob", // Set the responseType to 'blob'
          }
        )
        .then((res) => {
          if (res.status === 200) {
            setLoading(false);
            const fileData = new Blob([res.data], { type: "text/csv" });
            saveAs(fileData, "redemptionReport.csv");
          } else {
            swal({
              text: res.data.msg,
              // icon: 'error',
              timer: 2000,
              buttons: false,
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearchQuery = (event) => {
    setSelectStatus(event.target.value);
    if (event.target.value === "") {
      redemptionReport("");
      setSearchRecord();
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      redemptionReport(selectStatus);
    }
  };
  return (
    <>
      {loading ? <Oval /> : null}
      <Header />
      <section className="blog-area pt182">
        <div className="container">
          <div className="row">
            <div className="col-md-12 tab mb-20">
              <ul className="nav nav-tabs" role="tablist">
                <li role="presentation" className="active">
                  <a href="#Section1" role="tab" data-toggle="tab">
                    Redemption Report{" "}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="all-single-blog rpt">
                <div className="row clearfix mb-15 callwell">
                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <div className="date-feilds">
                      <DatePicker
                        selected={fromDate}
                        onChange={(date) => setFromDate(date)}
                        dateFormat="dd-MM-yyyy"
                        className="frmcntrl"
                        id="Fromdate"
                        showYearDropdown
                        showMonthDropdown
                        autoComplete="off"
                        scrollableYearDropdown
                        maxDate={new Date()}
                        onKeyDown={(e) => e.preventDefault()} // Prevent manual input using keyboard
                        onFocus={(e) => e.target.blur()} // Blur the input to prevent focus
                        onClick={(e) => e.preventDefault()} // Preven
                        placeholderText="From date"
                      />
                    </div>
                  </div>

                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <div className="date-feilds">
                      <DatePicker
                        selected={toDate}
                        onChange={(date) => setToDate(date)}
                        dateFormat="dd-MM-yyyy"
                        className="frmcntrl"
                        id="todate"
                        showYearDropdown
                        showMonthDropdown
                        autoComplete="off"
                        scrollableYearDropdown
                        maxDate={new Date()}
                        onKeyDown={(e) => e.preventDefault()} // Prevent manual input using keyboard
                        onFocus={(e) => e.target.blur()} // Blur the input to prevent focus
                        onClick={(e) => e.preventDefault()} // Preven
                        placeholderText="To date"
                      />
                    </div>
                  </div>

                  {/* <div className="col-md-3 col-sm-12 col-xs-12">
										<input className="frmcntrl" placeholder="Name/Mobile no." type="text" value={selectStatus} onChange={handleSearchQuery} onKeyDown={handleKeyDown} />

									</div> */}
                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <select
                      class="frmcntrl"
                      style={{ color: "Gray" }}
                      value={selectStatus}
                      onChange={(event) => setSelectStatus(event.target.value)}
                    >
                      <option value="">All</option>
                      <option value="OPEN">Open </option>
                      <option value="ORDERED">Ordered</option>
                      <option value="DELIVERED">Delivered </option>
                      <option value="PENDING">Pending </option>
                      <option value="CANCELLED">Cancelled </option>
                    </select>
                  </div>
                  <div className="col-md-3 col-sm-3 col-xs-12">
                    <button
                      type="button"
                      className="btn login_white_btn"
                      onClick={() => redemptionReport(selectStatus)}
                    >
                      {" "}
                      Search <img src={longarrow} alt="" />
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    {searchRecord === null ? (
                      ""
                    ) : (
                      <Link
                        className="btn login_btn fa fa-file-excel-o pull-right mt-10 mb-10"
                        to="#"
                        onClick={download}
                      >
                        {" "}
                        Export
                      </Link>
                    )}
                    <div className="carrd1">
                      <div className="card-body table-responsive">
                        <table className="table table-bordered mytable mb_01">
                          <thead>
                            <tr>
                              <th>S.No.</th>
                              {/* <th>Firm Name </th>
                              <th>Owner Name</th>
                              <th>Owner DOB </th>
                              <th>House / Flat / Block No.</th>
                              <th>Street / Colony / Locality Name</th>
                              <th>Landmark</th>
                              <th>Owner City </th>
                              <th>Owner District</th>
                              <th>Owner State</th>
                              <th>Owner Pin</th>
                              <th>Owner Contact </th>
                              <th>Owner Email </th>
                              <th>Firm SAP Code</th>
                              <th>House / Flat / Block No.</th>
                              <th>Street / Colony / Locality Name</th>
                              <th>Landmark</th>
                              <th>Professional City</th>
                              <th>Professional District</th>
                              <th>Professional State</th>
                              <th>Pin Code</th>
                              <th>GST No. </th>
                              <th>Pan Number </th>
                              <th>Spoc Name </th>
                              <th>Spoc Contact</th>
                              <th>Spoc Email ID</th>
                              <th>Sales MT</th>
                              <th>JK Sales Member Name</th>
                              <th>Member Contact </th>
                              <th>Member Email ID</th>
                              <th>Zone/State </th>
                              <th>Total Redemption </th>
                              <th>Redeem Points</th>
                              <th>Redemption Status</th>
                              <th>Sister Concern 1</th>
                              <th>Sister Concern 2</th>
                              <th>Sister Concern 3</th>
                              <th>Sister Concern 4</th>
                              <th>Sister Concern 5</th>
                              <th>Sister Concern 6</th>
                              <th>Sister Concern 7</th>
                              <th>Sister Concern 8</th>
                              <th>Sister Concern 9</th> */}
															<th>firmName</th>
															<th>OwnerName</th>
															<th>OwnerDOB</th>
															<th>HouseFlatBlockNo</th>
															<th>sTreetColonyLocalityName</th>
															<th>Landmark</th>
															<th>OwnerCity</th>
															<th>OwnerDistrict</th>
															<th>OwnerState</th>
															<th>OwnerPin</th>
															<th>OwnerContact</th>
															<th>firmSapCode</th>
															<th>OwnerEmail</th>
															<th>PofessionalHouseFlatBlockNo</th>
															<th>PofessionalStreetColonyLocalityName</th>
															<th>PofessionalLandmark</th>
															<th>ProfessionalCity</th>
															<th>ProfessionalDistrict</th>
															<th>ProfessionalState</th>
															<th>ProfessionalPin</th>
															<th>gstNo</th>
															<th>panNo</th>
															<th>spocName</th>
															<th>spocContact</th>
															<th>spocEmail</th>
															<th>salesMt</th>
															<th>JKSalesMemberName</th>
															<th>JKSalesMemberMobile</th>
															<th>JKSalesMemberEmail</th>
															<th>state</th>
															<th>redeemptionId</th>
															<th>RedeemPoints</th>
															<th>orderStatus</th>
															<th>RedemptionType</th>
															<th>RedemptionRequestDate</th>
															<th>deliveryAddress1</th>
															<th>deliveryAddress2</th>
															<th>deliveryAddress3</th>
															<th>deliveryState</th>
															<th>deliveryDistrict</th>
															<th>deliveryCity</th>
															<th>orderItemId</th>
															<th>productCode</th>
															<th>productName</th>
															<th>productRedeempoints</th>
															<th>productQuantity</th>
                              <th>egvDescription</th>
															<th>egvCode</th>
                            </tr>
                          </thead>
                          {searchRecord === null ? (
                            <tbody>
                              <tr>
                                <td
                                  colSpan="47"
                                  style={{
                                    textAlign: "center",
                                    fontSize: "16px",
                                  }}
                                >
                                  No record found!
                                </td>
                              </tr>
                            </tbody>
                          ) : (
                            <tbody>
                              {results &&
                                results.map((item, index) => {
                                  const serialNumber =
                                    (currentPage - 1) * limit + index + 1;
                                  // // const formattedDate = moment(item.user.createDateTime, 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY');
                                  // const sisterConcern1 = item.user.sisterConcern
                                  //   ? item.user.sisterConcern.sister1
                                  //   : "";
                                  // const sisterConcern2 = item.user.sisterConcern
                                  //   ? item.user.sisterConcern.sister2
                                  //   : "";
                                  // const sisterConcern3 = item.user.sisterConcern
                                  //   ? item.user.sisterConcern.sister3
                                  //   : "";
                                  // const sisterConcern4 = item.user.sisterConcern
                                  //   ? item.user.sisterConcern.sister4
                                  //   : "";
                                  // const sisterConcern5 = item.user.sisterConcern
                                  //   ? item.user.sisterConcern.sister5
                                  //   : "";
                                  // const sisterConcern6 = item.user.sisterConcern
                                  //   ? item.user.sisterConcern.sister6
                                  //   : "";
                                  // const sisterConcern7 = item.user.sisterConcern
                                  //   ? item.user.sisterConcern.sister7
                                  //   : "";
                                  // const sisterConcern8 = item.user.sisterConcern
                                  //   ? item.user.sisterConcern.sister8
                                  //   : "";
                                  // const sisterConcern9 = item.user.sisterConcern
                                  //   ? item.user.sisterConcern.sister9
                                  //   : "";
                                  return (
                                    <>
                                      <tr>
                                        <td>{serialNumber}</td>
																				<td>{item[0]}</td>
																				<td>{item[1]}</td>
																				<td>{item[2]}</td>
																				<td>{item[3]}</td>
																				<td>{item[4]}</td>
																				<td>{item[5]}</td>
																				<td>{item[6]}</td>
																				<td>{item[7]}</td>
																				<td>{item[8]}</td>
																				<td>{item[9]}</td>
																				<td>{item[10]}</td>
																				<td>{item[11]}</td>
																				<td>{item[12]}</td>
																				<td>{item[13]}</td>
																				<td>{item[14]}</td>
																				<td>{item[15]}</td>
																				<td>{item[16]}</td>
																				<td>{item[17]}</td>
																				<td>{item[18]}</td>
																				<td>{item[19]}</td>
																				<td>{item[20]}</td>
																				<td>{item[21]}</td>
																				<td>{item[22]}</td>
																				<td>{item[23]}</td>
																				<td>{item[24]}</td>
																				<td>{item[25]}</td>
																				<td>{item[26]}</td>
																				<td>{item[27]}</td>
																				<td>{item[28]}</td>
																				<td>{item[29]}</td>
																				<td>{item[30]}</td>
																				<td>{item[31]}</td>
																				<td>{item[32]}</td>
																				<td>{item[33]}</td>
																				<td>{item[34]}</td>
																				<td>{item[35]}</td>
																				<td>{item[36]}</td>
																				<td>{item[37]}</td>
																				<td>{item[38]}</td>
																				<td>{item[39]}</td>
																				<td>{item[40]}</td>
																				<td>{item[41]}</td>
																				<td>{item[42]}</td>
																				<td>{item[43]}</td>
																				<td>{item[44]}</td>
																				<td>{item[45]}</td>
                                        <td>{item[46]}</td>
                                        <td>{item[47]}</td>
                                        {/* <td>{item.user.firmName}</td>
                                        <td>{item.user.name}</td>
                                        <td>{item.user.dob}</td>
                                        <td>
                                          {
                                            item.user.addressModelPerm
                                              .permAddress1
                                          }
                                        </td>
                                        <td>
                                          {
                                            item.user.addressModelPerm
                                              .permAddress2
                                          }
                                        </td>
                                        <td>
                                          {
                                            item.user.addressModelPerm
                                              .permAddress3
                                          }
                                        </td>
                                        <td>
                                          {item.user.addressModelPerm.permCity}
                                        </td>
                                        <td>
                                          {
                                            item.user.addressModelPerm
                                              .permdistrict
                                          }
                                        </td>
                                        <td>
                                          {item.user.addressModelPerm.permState}
                                        </td>
                                        <td>
                                          {
                                            item.user.addressModelPerm
                                              .permPincode
                                          }
                                        </td>
                                        <td>{item.user.mobile}</td>
                                        <td>{item.user.email}</td>
                                        <td>{item.user.firmSapCode}</td>
                                        <td>
                                          {item.user.addressWork.workAddress1}
                                        </td>
                                        <td>
                                          {item.user.addressWork.workAddress2}
                                        </td>
                                        <td>
                                          {item.user.addressWork.workAddress3}
                                        </td>
                                        <td>
                                          {item.user.addressWork.workCity}
                                        </td>
                                        <td>
                                          {item.user.addressWork.workdistrict}
                                        </td>
                                        <td>
                                          {item.user.addressWork.workState}
                                        </td>
                                        <td>
                                          {item.user.addressWork.workPincode}
                                        </td>
                                        <td>{item.user.gst}</td>
                                        <td>
                                          {item.user.userModelKYC.panNumber}
                                        </td>
                                        <td>{item.user.spocName}</td>
                                        <td>{item.user.spocMobile}</td>
                                        <td>{item.user.spocEmail}</td>
                                        <td>{item.totalSalesReceived}</td>
                                        <td>{item.user.parentName}</td>
                                        <td>{item.user.parentMobile}</td>
                                        <td>{item.user.parentEmail}</td>
                                        <td>{item.user.parentZone}</td>
                                        <td>{item.user.redeemPoints}</td>
                                        <td>{item.user.points}</td>
                                        <td>{item.status}</td>
                                        <td>{sisterConcern1}</td>
                                        <td>{sisterConcern2}</td>
                                        <td>{sisterConcern3}</td>
                                        <td>{sisterConcern4}</td>
                                        <td>{sisterConcern5}</td>
                                        <td>{sisterConcern6}</td>
                                        <td>{sisterConcern7}</td>
                                        <td>{sisterConcern8}</td>
                                        <td>{sisterConcern9}</td> */}
                                      </tr>
                                    </>
                                  );
                                })}
                            </tbody>
                          )}
                        </table>
                        {totalPages > 1 && (
                          <ResponsivePagination
                            current={currentPage}
                            total={totalPages}
                            onPageChange={(page) => setCurrentPage(page)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
